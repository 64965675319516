import axios from "axios"
import jwtDefaultConfig from "./jwtDefaultConfig"
// ** Store & Actions
import message from "@src/components/toasts"

export const privateAxios = axios.create({
  baseURL: "https://api.dev.force-v.jinnahtech.com/api/",
})
export default class JwtService {
  // ** jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // ** For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // ** For Refreshing Token
  subscribers = []

  constructor(jwtOverrideConfig) {
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // ** Request Interceptor
    privateAxios.interceptors.request.use(
      (config) => {
        // ** Get token from localStorage
        const accessToken = this.getToken()

        // ** If token is present add it to request's Authorization Header
        if (accessToken) {
          // ** eslint-disable-next-line no-param-reassign
          config.headers.Accept = "application/json"
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
          // config.headers["Access-Control-Allow-Origin"] = "*";
        }
        return config
      },
      (error) => Promise.reject(error)
    )

    // ** Add request/response interceptor
    privateAxios.interceptors.response.use(
      (response) => response,
      (error) => {
        // ** const { config, response: { status } } = error
        const { config, response } = error
        const originalRequest = config

        // ** if (status === 401) {
        if (response && response.status === 401) {
          if (Date.now() >= JSON.parse(localStorage.getItem("refreshExpiry"))) {
            message.displayErrors("Your Session is expired!")
            localStorage.removeItem("userData")
            localStorage.removeItem(jwtDefaultConfig.storageTokenKeyName)
            localStorage.removeItem(jwtDefaultConfig.storageRefreshTokenKeyName)
            localStorage.removeItem(jwtDefaultConfig.storageTokenExpiryKeyName)
            localStorage.removeItem(
              jwtDefaultConfig.storageRefreshTokenExpiryKeyName
            )
            // Reload the current page
            window.location.reload()
            message.displayErrors("Your Session is expired!")
          }

          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true

            this.refreshToken().then((r) => {
              this.isAlreadyFetchingAccessToken = false

              // ** Update accessToken in localStorage
              this.setToken(`"${r.data.access}"`)
              this.setRefreshToken(`"${this.getRefreshToken()}"`)

              this.onAccessTokenFetched(r.data.access)
            })
          }
          const retryOriginalRequest = new Promise((resolve) => {
            this.addSubscriber((accessToken) => {
              // ** Make sure to assign accessToken according to your response.
              // ** Check: https://pixinvent.ticksy.com/ticket/2413870
              // ** Change Authorization header
              // originalRequest.headers.Authorization = "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjU1OTg0MzAzLCJpYXQiOjE2NTU5ODEyNzQsImp0aSI6Ijg4MWIwYWUzNTk5NDRlNjRhYTI2YTJjMGM1Y2U5MTk1IiwidXNlcl9pZCI6MX0.VRIgvOwdrqQsadNxF6fm954nNocVVU0dbu4KoJ27XS0"
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType}  ${accessToken}`
              resolve(privateAxios(originalRequest))
            })
          })
          return retryOriginalRequest
        }
        return Promise.reject(error)
      }
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter((callback) =>
      callback(accessToken)
    )
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage
      .getItem(this.jwtConfig.storageTokenKeyName)
      ?.slice(1, -1)
  }

  getRefreshToken() {
    return localStorage
      .getItem(this.jwtConfig.storageRefreshTokenKeyName)
      ?.slice(1, -1)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    return privateAxios.post(this.jwtConfig.loginEndpoint, ...args)
  }

  register(...args) {
    return privateAxios.post(this.jwtConfig.registerEndpoint, ...args)
  }

  refreshToken() {
    return privateAxios.post(this.jwtConfig.refreshEndpoint, {
      refresh: this.getRefreshToken(),
    })
  }

  getURL() {
    return privateAxios
  }
}
